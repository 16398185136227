<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" :sz="modalSz" :helpCopy="helpCopy">
      <template v-slot:header>
        {{ formTitle }}
      </template>

      <template v-slot:body>

        <div v-if="isConfirmingDelete" class="p-4">
          <b>Just a Quick Confirmation...</b>
          <br />
          <br />
          Are you sure you want to delete this {{ elementType }}? 
          Press Confirm or Cancel below to proceed.
        </div>

        <div v-else>
        <div v-for="(field,i) of fields1" :key="i">
          <ElementIdRow v-if="field.controlType === 'id' && formData && formData.id && field.hidden !== true"
            class="ps-2 mt-0 mb-3 pe-2 pb-2 add-border-bottom subtle"
            :label="field.label || 'ID'"
            :value="formData.id"
            :hideLabel="field.hideLabel || false"
          />
          <SectionHeading :label="field.label" :headingClass="field.headingClass"
            v-else-if="field.controlType === 'sectionHeader' && field.hidden !== true" />
          <TextInputRow v-else-if="field.controlType === 'textInput' && field.hidden !== true"
            :label="field.label"
            v-model="formData[field.field]"
            :labelClass="labelClass(field.labelClass, field.required)"
            :inputClass="field.inputClass"
            :required="field.required ? true : false"
            :placeholder="field.placeholder || ''"
            :dataType="field.dataType || ''"
            :suffix="field.suffix"
            :disabled="field.disabled ? true : false"
            :valueChangedFunc="formDataChanged"
          />
          <SelectRow v-else-if="field.controlType === 'selectInput' && field.hidden !== true"
            :label="field.label"
            v-model="formData[field.field]"
            :labelClass="labelClass(field.labelClass, field.required)"
            :selectClass="field.inputClass"
            :required="field.required ? true : false"
            :placeholder="field.placeholder || ''"
            :dataType="field.dataType || ''"
            :suffix="field.suffix"
            :options="field.options"
            :disabled="field.disabled ? true : false"
            :noSelectionLabel="field.noSelectionLabel"
            @change="formDataChanged"
          />
        </div>

        <LastModifiedRow :ts="data.lastModified" :author="data.lastModifiedBy" v-if="data && 'lastModified' in data"/>
        </div>

      </template>
      <template v-slot:footer>
        <div class="col-auto buttons-right">
          <button class="btn" @click="submit()" :disabled="!canSubmit" v-if="!isConfirmingDelete">Submit</button>
          <button class="btn btn-red ms-3" @click="confirmDeleteElement()" :disabled="isLoading" v-if="deleteFunc && !isConfirmingDelete">Delete {{ elementType }}</button>
          <button class="btn btn-red ms-3" @click="deleteElement()" :disabled="isLoading" v-if="deleteFunc && isConfirmingDelete">Confirm &amp; Delete {{ elementType }}</button>
          <a class="btn btn-blue ms-3" @click.prevent="cancelDeleteElement()" v-if="deleteFunc && isConfirmingDelete" >Cancel</a>
          <a class="btn btn-blue ms-3" @click.prevent="close()" v-else>Cancel</a>
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script>
import Modal from '@/components/Modal.vue'
import ElementIdRow from '@/components/forms/ElementIdRow.vue'
import TextInputRow from '@/components/forms/TextInputRow.vue'
import SelectRow from '@/components/forms/SelectRow.vue'
import SectionHeading from '@/components/forms/SectionHeading.vue'
import LastModifiedRow from '@/components/forms/LastModifiedRow.vue'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'

export default {
  name: 'modal-submission-form',
  props: ['title', 'sz', 'data', 'fields1', 'updateFunc', 'createFunc', 'deleteFunc',
    'elementType', 'formDataChangeFunc', 'helpCopy', 'isCreate', 'closer'],
  data () {
    return {
      isLoading: false,
      isBlocking: false,
      formData: {},
      isConfirmingDelete: false
    }
  },
  computed: {
    action () {
      return this.isCreateModal ? 'Create' : 'Update'
    },
    element () {
      return this.elementType ? this.elementType : 'Item'
    },
    formTitle () {
      if (this.title) {
        return title
      }
      return `${this.action} ${this.element}`
    },
    isCreateModal () {
      return this.isCreate || !this.data
    },
    modalSz () {
      if (!this.sz) {
        return 'sm'
      }
      return this.sz
    },
    canSubmit () {
      if (this.isLoading) {
        return false
      }
      if (!this.fields1) {
        return true
      }
      for (const field of this.fields1) {
        const isRequired = 'required' in field && field.required === true
        if (!isRequired) {
          continue
        }
        const fieldExists = field.field in this.formData && this.formData[field.field] !== ''
        if (!fieldExists) {
          return false
        }
      }
      return true
    },
    submitFunc () {
      if (this.isCreateModal && this.createFunc) {
        return this.createFunc
      }
      if (!this.isCreateModal && this.updateFunc) {
        return this.updateFunc
      }
      return undefined
    }
  },
  methods: {
    formDataChanged (e) {
      if (this.formDataChangeFunc) {
        this.formDataChangeFunc(this.formData)
      }
    },
    labelClass (className, required) {
      if (!className && !required) {
        return ""
      }
      if (className && required) {
        return `${className} label-required`
      }
      if (required) {
        return 'label-required'
      }
      return className || ""
    },
    submit () {
      if (!this.submitFunc) {
        return
      }
      this.isLoading = true
      this.submitFunc(this.formData)
      .then(() => {
        HelpContent.setTimedFlashMessage(`${this.element} ${this.action}d`)
        this.$nextTick(() => {
          Reloadables.requestReload()
          this.close()
        })
      })
      .catch(e => {
        let message = `${this.element} ${this.action} Failed`
        if (e.response && e.response.data && e.response.data.message) {
          message += `: ${e.response.data.message}`
        }
        HelpContent.setFlashMessage(message, true)
      })
      .finally(() => {
        this.isLoading = false
      })
    },
    cancelDeleteElement () {
      this.isConfirmingDelete = false
    },
    confirmDeleteElement () {
      this.isConfirmingDelete = true
    },
    deleteElement () {
      if (!this.deleteFunc) {
        return
      }
      this.isLoading = true
      this.deleteFunc(this.data.id)
      .then(() => {
        HelpContent.setTimedFlashMessage(`${this.element} deleted`)
        this.$nextTick(() => {
          Reloadables.requestReload()
          this.close()
        })
      })
      .catch(e => {
        let message = `${this.element} deletion Failed`
        if (e.response && e.response.data && e.response.data.message) {
          message += `: ${e.response.data.message}`
        }
        HelpContent.setFlashMessage(message, true)
      })
      .finally(() => {
        this.isLoading = false
      })
    },
    reset () {
      this.isLoading = false
      this.isConfirmingDelete = false
    },
    show () {
      this.reset()
      this.isBlocking = true
      if (this.data) {
        this.formData = { ...this.data }
        delete this.formData.lastModified
        delete this.formData.lastModifiedBy
        delete this.formData.createdAt
        delete this.formData.createdBy
      }
    },
    close () {
      this.formData = {}
      this.$refs.baseModal.close()
      if (this.closer) {
        this.closer()
      }
      this.isBlocking = false
    },
  },
  components: { Modal, ElementIdRow, TextInputRow, LastModifiedRow, SelectRow, SectionHeading }
}
</script>