<template>
  <transition name="modal">
    <modal v-if="isBlocking" @close="close()" sz="sm">

        <template v-slot:header>
        Settings & Personalization
        </template>

        <template v-slot:body>

          <div class="row prefs-row">
            <div class="col-auto prefs-row-header">
              Inactive Items
            </div>
            <div class="col-auto">
              <span class="attribute-label-left">
                <input id="showInactives" class="form-check-input" type="checkbox" v-model="$state.showInactiveNodes" ref="inactiveCheckbox" />
              </span>
              <label for="showInactives">Show</label>
            </div>
          </div>

          <hr class="info-sep mt-2" />

          <div class="row prefs-row mt-3 pt-2 mb-0 pb-0">
            <div class="col-auto prefs-row-header mt-1">
              Auto-Refresh
            </div>
            <div class="col-auto">
              <span class="attribute-label-left">
                <select class="form-select" v-model="$state.refreshInterval" ref="refreshIntervalSelect">
                  <option :value="0">Never</option>
                  <option :value="180000">Every 3 Minutes</option>
                  <option :value="60000">Every 1 Minute</option>
                  <option :value="30000">Every 30 Seconds</option>
                  <option :value="15000">Every 15 Seconds</option>
                </select>
              </span>
            </div>
          </div>

          <hr class="info-sep mt-0" />

          <div class="row prefs-row my-3 pt-1 pb-0">
            <div class="col-auto prefs-row-header">
              Theme
            </div>
            <div class="col-auto">

              <div v-for="(tn,i) of themes" :key="tn" v-bind:class="{'pt-2': i > 0}" >
                <span class="prefs-row-data">
                  <input class="form-check-input" type="radio" name="theme" v-bind:id="'theme-' + tn" @click="toggleTheme(tn)" v-bind:value="tn" v-model="theme"/>
                </span>
                <label v-bind:for="'theme-' + tn">{{ themeLabels[i] }}</label>
              </div>

            </div>
          </div>

        </template>

    </modal>
  </transition>
</template>

<script>
import Modal from '@/components/Modal.vue'

export default {
  data () {
    return {
      isBlocking: false,
      themes: ['darkMode', 'lightMode', 'highContrastMode'],
      themeLabels: ['Dark', 'Light', 'High-Contrast'],
      theme: 'darkMode'
    }
  },
  components: { Modal },
  methods: {
    show () {
      this.isBlocking = true
    },
    close () {
      if (this.$state.showInactiveNodes) {
        localStorage.setItem('prefs.show_inactive', 1)
      } else {
        localStorage.removeItem('prefs.show_inactive')
      }
      if (('refreshInterval' in this.$state)) {
        localStorage.setItem('prefs.refreshInterval', this.$state.refreshInterval)
      } else {
        localStorage.setItem('prefs.refreshInterval', 0)
      }
      this.isBlocking = false
    },
    toggleTheme (theme) {
      document.documentElement.setAttribute('data-theme', theme)
      localStorage.setItem('prefs.theme', theme)
    }
  },
  mounted () {
    if (localStorage.getItem('prefs.refreshInterval')) {
      this.$state.refreshInterval = localStorage.getItem('prefs.refreshInterval')
    }
    if (!('refreshInterval' in this.$state)) {
      this.$state.refreshInterval = 0
      localStorage.setItem('prefs.refreshInterval', this.$state.refreshInterval)
    }
    if (localStorage.getItem('prefs.show_inactive') === '1') {
      this.$state.showInactiveNodes = true
    }
    if (localStorage.getItem('prefs.theme')) {
      this.theme = localStorage.getItem('prefs.theme')
    }
  }
}
</script>
